.ant-popover-title {
    background-color: #ebedef;
    color: #8a93a2;
}

.ant-popover-inner-content {
    padding: 0px;
}


.user-bar-field {
    align-items: center;
    min-width: 180px;
    color: #4f5d73;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 8px;
    padding-left: 15px;
}

.user-bar-field-icon {
    padding-right: 5px;
}

.user-bar-field:hover {
    background-color:#ebedef;
    cursor: pointer;
    font-weight: 500;
}

