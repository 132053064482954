.container-layout {
    margin: 24px 16px 0;
    margin-top: 80px;
    overflow: initial;
    min-height: calc(100vh - 150px)
}

.ant-form-horizontal .ant-form-item-label {
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 50% !important;
  }
  
  .ant-form-horizontal .ant-form-item-control {
    -ms-flex: 1 1 0px;
    flex: 1 1;
    width: 50% !important;
  }