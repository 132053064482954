.loadingPage{
    top: 0;
    left: 0;
    background-color: rgb(25,31,38);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    z-index: 99999999;
    pointer-events: none;
}

.img-loader {
  margin-top: 20%;
  pointer-events: none;
}
