  .login-page-layout{
    min-height: 100vh;
    background-color: black;
  }

  .login-page-row-sup{
    height: 300px;
  }

  .logo {
    float: left;
    width: 200px;
    height: 60px;
    margin: 0px 0px 0px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  
