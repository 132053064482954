  .principal-layout{
      height: 100vh;
  }

  .logo {
    float: left;
    width: 200px;
    height: 60px;
    margin: 0px 0px 0px 0;
    background: rgba(255, 255, 255, 0.3);
    
  }
  
